import { InstitutionCheckExceptionReturnReasonDto } from '@treasury/api/channel';
import { ReturnReasonsDescriptions } from '../channel/types';
import { DomainEntity } from '../shared';

export class InstitutionCheckExceptionReturnReason extends DomainEntity<InstitutionCheckExceptionReturnReasonDto> {
    constructor(dto: InstitutionCheckExceptionReturnReasonDto) {
        super(dto);
    }

    public createDefault(): this {
        return new InstitutionCheckExceptionReturnReason({
            description: '',
            returnReasonUniqueId: '',
            isDefault: false,
            isDeleted: false,
        }) as this;
    }

    get description() {
        return (
            (this.dto.description as ReturnReasonsDescriptions) ?? ReturnReasonsDescriptions.None
        );
    }

    get returnReasonUniqueId() {
        return this.dto.returnReasonUniqueId;
    }

    get isDefault() {
        return this.dto.isDefault;
    }

    get isDeleted() {
        return this.dto.isDeleted;
    }
}
